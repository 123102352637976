import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import ChatArea from "./ChatArea";
import ChatInput from "./ChatInput";
import RenameModal from "./RenameModal";
import Popup from "./Popup";
import { getAuth } from "firebase/auth";

function WelcomePage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [renameChatId, setRenameChatId] = useState(null);
  const [newChatName, setNewChatName] = useState("");
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLimitPopupVisible, setIsLimitPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Check daily chat limit
  const checkDailyChatLimit = () => {
    const today = new Date().toISOString().slice(0, 10);
    const dailyUsage = JSON.parse(localStorage.getItem("dailyChatUsage")) || {
      date: today,
      count: 0,
    };

    if (dailyUsage.date !== today) {
      localStorage.setItem(
        "dailyChatUsage",
        JSON.stringify({ date: today, count: 0 })
      );
      return 0;
    }

    return dailyUsage.count;
  };

  const incrementDailyChatCount = () => {
    const today = new Date().toISOString().slice(0, 10);
    const dailyUsage = JSON.parse(localStorage.getItem("dailyChatUsage")) || {
      date: today,
      count: 0,
    };

    const updatedUsage = {
      date: today,
      count: dailyUsage.count + 1,
    };

    localStorage.setItem("dailyChatUsage", JSON.stringify(updatedUsage));
  };

  // Function to make the bot's response audible
  const speakText = (text) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "en-US";
      utterance.rate = 1;
      utterance.pitch = 1;
      window.speechSynthesis.speak(utterance);
    } else {
      toast.error("Text-to-Speech is not supported in this browser.");
    }
  };

  const handleSubscription = async () => {
    setIsLoading(true); // Start loading spinner
    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    };

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error(
        "Failed to load Razorpay SDK. Check your internet connection."
      );
      setIsLoading(false); // Stop loading spinner
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        toast.error("User not logged in. Please log in first.");
        setIsLoading(false); // Stop loading spinner
        return;
      }

      const name = user.displayName || "Your Name";
      const email = user.email || "your.email@example.com";

      const subscriptionResponse = await axios.post(
        "https://thinkingai-backend-server.com/create-subscription",
        {
          plan_id: "plan_PNcPIw9lfrczy2",
          customer_notify: 1,
          total_count: 120,
        }
      );

      const subscription = subscriptionResponse.data;

      const options = {
        key: "rzp_live_EAvzuQ7vxdvCpX",
        subscription_id: subscription.id,
        name: "ThinkingAI",
        description: "Pro Plan Subscription",
        handler: function (response) {
          toast.success("Subscription successful!");
          console.log("Payment successful:", response);
        },
        prefill: {
          name: name,
          email: email,
          contact: "9999999999",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            setIsLoading(false);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      console.error(
        "Error creating subscription:",
        err.response || err.message
      );
      toast.error("Failed to create subscription. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async (userInput) => {
    if (!userInput.trim()) return;

    const dailyCount = checkDailyChatLimit();
    if (dailyCount >= 5) {
      setIsLimitPopupVisible(true);
      return;
    }

    incrementDailyChatCount();

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), text: userInput, type: "user" },
    ]);
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api-inference.huggingface.co/models/facebook/blenderbot-400M-distill",
        JSON.stringify({ inputs: userInput }),
        {
          headers: {
            Authorization: `Bearer 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`,
            "Content-Type": "application/json",
          },
        }
      );

      const botMessageText =
        response?.data?.generated_text ||
        (Array.isArray(response.data) && response.data[0]?.generated_text) ||
        "I'm not sure how to respond to that.";

      const newBotMessage = {
        id: Date.now(),
        text: botMessageText,
        type: "bot",
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newBotMessage];
        updateChatMessagesInHistory(updatedMessages);
        return updatedMessages;
      });

      speakText(botMessageText);
    } catch (error) {
      console.error("Error fetching response from Hugging Face API:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now(),
          text: "Sorry, I couldn't process that.",
          type: "bot",
        },
      ]);
      speakText("Sorry, I couldn't process that.");
    } finally {
      setLoading(false);
    }
  };

  const appInstallation = () => {
    toast.info("The app is under development. It will be released soon.");
  };

  const updateChatMessagesInHistory = (newMessages) => {
    setChatHistory((prevChatHistory) =>
      prevChatHistory.map((chat) => {
        if (chat.id === selectedChatId) {
          return { ...chat, messages: newMessages };
        }
        return chat;
      })
    );
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData || !userData.displayName) {
      navigate("/name-email");
    }

    const storedChatHistory = JSON.parse(localStorage.getItem("chatHistory"));
    if (storedChatHistory) {
      setChatHistory(storedChatHistory);
      if (storedChatHistory.length > 0) {
        setSelectedChatId(storedChatHistory[0].id);
        setMessages(storedChatHistory[0].messages || []);
      }
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  }, [chatHistory]);

  return (
    <div className="welcome-page">
      <ToastContainer position="top-right" autoClose={3000} />
      {!isSidebarOpen && (
        <button
          className="sidebar-toggle"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          ☰
        </button>
      )}
      <Sidebar
        isOpen={isSidebarOpen}
        closeSidebar={() => setIsSidebarOpen(false)}
        handleNewChat={() => {
          const newChat = { id: Date.now(), name: "New Chat", messages: [] };
          setChatHistory((prev) => [...prev, newChat]);
          setSelectedChatId(newChat.id);
          setMessages([
            { id: 1, text: "New conversation started.", type: "bot" },
          ]);
        }}
        chatHistory={chatHistory}
        setMessages={setMessages}
        handleDeleteChat={(chatId) => {
          setChatHistory((prev) => prev.filter((chat) => chat.id !== chatId));
          setSelectedChatId(null);
          setMessages([{ id: 1, text: "Welcome to ThinkingAI!", type: "bot" }]);
        }}
        startRenameChat={(chatId) => {
          setRenameChatId(chatId);
          const chat = chatHistory.find((ch) => ch.id === chatId);
          setNewChatName(chat?.name || "");
        }}
        handleSelectChat={(chatId) => {
          const selectedChat = chatHistory.find((chat) => chat.id === chatId);
          setSelectedChatId(chatId);
          setMessages(selectedChat?.messages || []);
        }}
        selectedChatId={selectedChatId}
      />
      <ProfileMenu setQrCodeDataUrl={setQrCodeDataUrl} />
      <div className="main-chat">
        <ChatArea
          messages={messages}
          loading={loading}
          suggestions={suggestions}
        />
        <div className="speech-controls">
          <button
            onClick={appInstallation}
            className={`speech-button ${isListening ? "listening" : ""}`}
          >
            {"❇️ Start APP"}
          </button>
        </div>
        <ChatInput onSendMessage={handleSendMessage} />
      </div>
      {renameChatId && (
        <RenameModal
          newChatName={newChatName}
          setNewChatName={setNewChatName}
          handleRenameChat={() => {
            setChatHistory((prev) =>
              prev.map((chat) =>
                chat.id === renameChatId ? { ...chat, name: newChatName } : chat
              )
            );
            setRenameChatId(null);
          }}
          closeModal={() => setRenameChatId(null)}
        />
      )}
      {qrCodeDataUrl && (
        <Popup
          title="Connect with Other Devices"
          message="Scan this code to connect with other devices!"
          qrCodeDataUrl={qrCodeDataUrl}
          onClose={() => setQrCodeDataUrl("")}
        />
      )}
      {isLimitPopupVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              textAlign: "center",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <h2 style={{ marginBottom: "10px", fontSize: "1.5rem" }}>
              Daily Chat Limit Reached
            </h2>
            <p style={{ marginBottom: "20px", fontSize: "1rem" }}>
              You have reached your daily free chat limit. Upgrade to Pro for
              unlimited access.
            </p>
            <button
              style={{
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                padding: "10px 20px",
                fontSize: "1rem",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={handleSubscription}
            >
              Upgrade to Pro
            </button>
            <button
              style={{
                backgroundColor: "#6c757d",
                color: "white",
                border: "none",
                padding: "10px 20px",
                fontSize: "1rem",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => setIsLimitPopupVisible(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WelcomePage;
